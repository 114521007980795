// src/App.js
import NotificationPoller from './components/NotificationPoller';


function App() {
  return (
    <div>
      <h1>消息通知系统</h1>
      <NotificationPoller />
    </div>

  );
}

export default App;